
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _403cT1gKJUHiHMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/403.vue?macro=true";
import { default as about_45us71631P0xkhMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/about-us.vue?macro=true";
import { default as indexKnat8WtPw9Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/cars/[brand]/index.vue?macro=true";
import { default as indexSy9JlyD8QxMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/cars/index.vue?macro=true";
import { default as companiesVw9qqUTjo3Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/companies.vue?macro=true";
import { default as financeNc7nv9qmhvMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/finance.vue?macro=true";
import { default as indexZrSgmdNpqCMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/index.vue?macro=true";
import { default as indexVFqHLYJqsXMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/parts/index.vue?macro=true";
import { default as requestsi4RwsvEAhpMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/requests.vue?macro=true";
import { default as reviews31zq4Yaj7pMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/reviews.vue?macro=true";
import { default as usersA1e7ZxLKCVMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/users.vue?macro=true";
import { default as indexd4zF6pPbmWMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/index.vue?macro=true";
import { default as _91id_93VakHo7WuGuMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue?macro=true";
import { default as addMSN2LSPB53Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/add.vue?macro=true";
import { default as index1hGeMIbunhMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/index.vue?macro=true";
import { default as ordersAGKFQtCiMTMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/orders.vue?macro=true";
import { default as forgot_45passwordbGd9IuBjSVMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/forgot-password.vue?macro=true";
import { default as index6fKfFk4K9HMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/index.vue?macro=true";
import { default as loginbvAlbGdtpbMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/login.vue?macro=true";
import { default as carDBPCfEuE2fMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/car.vue?macro=true";
import { default as editBMiqp3PfKjMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/edit.vue?macro=true";
import { default as indexugWTDakyfoMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/index.vue?macro=true";
import { default as confirm_45request3jFNz6YfKPMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/confirm-request.vue?macro=true";
import { default as indexL2BTKmwKfKMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/index.vue?macro=true";
import { default as send_45reviewlZFIlM1866Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue?macro=true";
import { default as index2B2rlRDK35Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/index.vue?macro=true";
import { default as change_45aboutj02e60aVhUMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-about.vue?macro=true";
import { default as change_45address5ftmqPumQoMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-address.vue?macro=true";
import { default as change_45emailCzZZ406sWMMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-email.vue?macro=true";
import { default as change_45nameSajP3f4iDVMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-name.vue?macro=true";
import { default as change_45phoneF0BaOtwgiwMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-phone.vue?macro=true";
import { default as change_45websiteDmHkMMkkyEMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-website.vue?macro=true";
import { default as changeCcTL14ZU4DMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change.vue?macro=true";
import { default as _91delivery_93RHoI96gC67Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue?macro=true";
import { default as createsmpsW5GEA1Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue?macro=true";
import { default as _91payment_939LfOw7Flz8Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue?macro=true";
import { default as createvO6TBGABvqMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue?macro=true";
import { default as _91delivery_93C9K1AOgiePMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue?macro=true";
import { default as _91brandId_93VZgzjxXeGxMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/brands/[brandId].vue?macro=true";
import { default as indexELIQPyE5AIMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/index.vue?macro=true";
import { default as _91modelId_9375mGmyIzJPMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/models/[modelId].vue?macro=true";
import { default as _91partId_93ofa4XKlDcRMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/[partId].vue?macro=true";
import { default as indexdnSRy7XBMBMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/index.vue?macro=true";
import { default as indexjgRgMr2DiqMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/index.vue?macro=true";
import { default as index1wi3f9eXGUMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/model/[model]/index.vue?macro=true";
import { default as _91id_93mTHNUfR0gAMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[id].vue?macro=true";
import { default as indexr1BXsx99SkMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/index.vue?macro=true";
import { default as indexlDeh8myuKIMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/index.vue?macro=true";
import { default as indexQdJRREzt83Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/index.vue?macro=true";
import { default as indexXADfeADn5jMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/index.vue?macro=true";
import { default as send_45offerbgbvkzFDtPMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-offer.vue?macro=true";
import { default as send_45reviewYrDrM5DvfSMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-review.vue?macro=true";
import { default as index2wsHnC3NQSMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/index.vue?macro=true";
import { default as _91payment_93mcHzIJEeQRMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue?macro=true";
import { default as responseEibv5v2ENSMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/response.vue?macro=true";
import { default as settings0I2jNIFcEJMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/settings.vue?macro=true";
import { default as notificationsMqQz3wpp0sMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/notifications.vue?macro=true";
import { default as become_45sellersI6ump9t0aMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/become-seller.vue?macro=true";
import { default as indexwMeS2n67SgMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/index.vue?macro=true";
import { default as _91hash_931lgRYiTcOsMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/password-reset/[hash].vue?macro=true";
import { default as change_45name27FoAiLroFMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-name.vue?macro=true";
import { default as change_45phonegBs3lDmgEHMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-phone.vue?macro=true";
import { default as indexfDzw5m4X88Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/index.vue?macro=true";
import { default as registerGBaFG5I6miMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/register.vue?macro=true";
import { default as requestky05NOJUC7Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/request.vue?macro=true";
import { default as change_45email6XD6znkaudMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/change-email.vue?macro=true";
import { default as change_45passwordZNFcLsjlCzMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/change-password.vue?macro=true";
import { default as indexjI0GsFeuMkMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/index.vue?macro=true";
import { default as indexP9q3i83eCzMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/index.vue?macro=true";
import { default as ratingWnMMU9YeIKMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/rating.vue?macro=true";
import { default as indexhoS2IIHOLUMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/support/index.vue?macro=true";
import { default as _91id_93HSXz8I1ga6Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/transactions/[id].vue?macro=true";
import { default as index4Pajql4nP9Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/transactions/index.vue?macro=true";
import { default as failuremhSOrUtCRMMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/failure.vue?macro=true";
import { default as indexJRXpYOz2RbMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/index.vue?macro=true";
import { default as infoo9EmPlkt0zMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/info.vue?macro=true";
import { default as transactionsERLdx5tByPMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/transactions.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/403.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/about-us.vue")
  },
  {
    name: "admin-cars-brand",
    path: "/admin/cars/:brand()",
    meta: indexKnat8WtPw9Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/cars/[brand]/index.vue")
  },
  {
    name: "admin-cars",
    path: "/admin/cars",
    meta: indexSy9JlyD8QxMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/cars/index.vue")
  },
  {
    name: "admin-companies",
    path: "/admin/companies",
    meta: companiesVw9qqUTjo3Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/companies.vue")
  },
  {
    name: "admin-finance",
    path: "/admin/finance",
    meta: financeNc7nv9qmhvMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/finance.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexZrSgmdNpqCMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/index.vue")
  },
  {
    name: "admin-parts",
    path: "/admin/parts",
    meta: indexVFqHLYJqsXMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/parts/index.vue")
  },
  {
    name: "admin-requests",
    path: "/admin/requests",
    meta: requestsi4RwsvEAhpMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/requests.vue")
  },
  {
    name: "admin-reviews",
    path: "/admin/reviews",
    meta: reviews31zq4Yaj7pMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/reviews.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersA1e7ZxLKCVMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/admin/users.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexd4zF6pPbmWMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-my-cars-id",
    path: "/dashboard/my-cars/:id()",
    meta: _91id_93VakHo7WuGuMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue")
  },
  {
    name: "dashboard-my-cars-add",
    path: "/dashboard/my-cars/add",
    meta: addMSN2LSPB53Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/add.vue")
  },
  {
    name: "dashboard-my-cars",
    path: "/dashboard/my-cars",
    meta: index1hGeMIbunhMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/index.vue")
  },
  {
    name: "dashboard-orders",
    path: "/dashboard/orders",
    meta: ordersAGKFQtCiMTMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/orders.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordbGd9IuBjSVMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginbvAlbGdtpbMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/login.vue")
  },
  {
    name: "my-requests-id-car",
    path: "/my-requests/:id()/car",
    meta: carDBPCfEuE2fMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/car.vue")
  },
  {
    name: "my-requests-id-edit",
    path: "/my-requests/:id()/edit",
    meta: editBMiqp3PfKjMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/edit.vue")
  },
  {
    name: "my-requests-id",
    path: "/my-requests/:id()",
    meta: indexugWTDakyfoMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/index.vue")
  },
  {
    name: "my-requests-id-request-offer-requestofferid-confirm-request",
    path: "/my-requests/:id()/request-offer/:requestofferid()/confirm-request",
    meta: confirm_45request3jFNz6YfKPMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/confirm-request.vue")
  },
  {
    name: "my-requests-id-request-offer-requestofferid",
    path: "/my-requests/:id()/request-offer/:requestofferid()",
    meta: indexL2BTKmwKfKMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/index.vue")
  },
  {
    name: "my-requests-id-send-review",
    path: "/my-requests/:id()/send-review",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue")
  },
  {
    name: "my-requests",
    path: "/my-requests",
    meta: index2B2rlRDK35Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/index.vue")
  },
  {
    name: "my-shop-change-about",
    path: "/my-shop/change-about",
    meta: change_45aboutj02e60aVhUMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-about.vue")
  },
  {
    name: "my-shop-change-address",
    path: "/my-shop/change-address",
    meta: change_45address5ftmqPumQoMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-address.vue")
  },
  {
    name: "my-shop-change-email",
    path: "/my-shop/change-email",
    meta: change_45emailCzZZ406sWMMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-email.vue")
  },
  {
    name: "my-shop-change-name",
    path: "/my-shop/change-name",
    meta: change_45nameSajP3f4iDVMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-name.vue")
  },
  {
    name: "my-shop-change-phone",
    path: "/my-shop/change-phone",
    meta: change_45phoneF0BaOtwgiwMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-phone.vue")
  },
  {
    name: "my-shop-change-website",
    path: "/my-shop/change-website",
    meta: change_45websiteDmHkMMkkyEMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-website.vue")
  },
  {
    name: "my-shop-change",
    path: "/my-shop/change",
    meta: changeCcTL14ZU4DMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change.vue")
  },
  {
    name: "my-shop-custom-delivery-delivery",
    path: "/my-shop/custom-delivery/:delivery()",
    meta: _91delivery_93RHoI96gC67Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue")
  },
  {
    name: "my-shop-custom-delivery-create",
    path: "/my-shop/custom-delivery/create",
    meta: createsmpsW5GEA1Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue")
  },
  {
    name: "my-shop-custom-payment-method-payment",
    path: "/my-shop/custom-payment-method/:payment()",
    meta: _91payment_939LfOw7Flz8Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue")
  },
  {
    name: "my-shop-custom-payment-method-create",
    path: "/my-shop/custom-payment-method/create",
    meta: createvO6TBGABvqMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue")
  },
  {
    name: "my-shop-delivery-delivery",
    path: "/my-shop/delivery/:delivery()",
    meta: _91delivery_93C9K1AOgiePMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue")
  },
  {
    name: "my-shop-filters-filterId-cars-brands-brandId",
    path: "/my-shop/filters/:filterId()/cars/brands/:brandId()",
    meta: _91brandId_93VZgzjxXeGxMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/brands/[brandId].vue")
  },
  {
    name: "my-shop-filters-filterId-cars",
    path: "/my-shop/filters/:filterId()/cars",
    meta: indexELIQPyE5AIMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/index.vue")
  },
  {
    name: "my-shop-filters-filterId-cars-models-modelId",
    path: "/my-shop/filters/:filterId()/cars/models/:modelId()",
    meta: _91modelId_9375mGmyIzJPMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/models/[modelId].vue")
  },
  {
    name: "my-shop-filters-filterId-parts-partId",
    path: "/my-shop/filters/:filterId()/parts/:partId()",
    meta: _91partId_93ofa4XKlDcRMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/[partId].vue")
  },
  {
    name: "my-shop-filters-filterId-parts",
    path: "/my-shop/filters/:filterId()/parts",
    meta: indexdnSRy7XBMBMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/index.vue")
  },
  {
    name: "my-shop-filters-add-filter-brand-brand",
    path: "/my-shop/filters/add-filter/brand/:brand()",
    meta: indexjgRgMr2DiqMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/index.vue")
  },
  {
    name: "my-shop-filters-add-filter-brand-brand-model-model",
    path: "/my-shop/filters/add-filter/brand/:brand()/model/:model()",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/model/[model]/index.vue")
  },
  {
    name: "my-shop-filters-add-filter-brand-id",
    path: "/my-shop/filters/add-filter/brand/:id()",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[id].vue")
  },
  {
    name: "my-shop-filters-add-filter",
    path: "/my-shop/filters/add-filter",
    meta: indexr1BXsx99SkMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/add-filter/index.vue")
  },
  {
    name: "my-shop-filters",
    path: "/my-shop/filters",
    meta: indexlDeh8myuKIMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/filters/index.vue")
  },
  {
    name: "my-shop",
    path: "/my-shop",
    meta: indexQdJRREzt83Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/index.vue")
  },
  {
    name: "my-shop-my-requests-sellerrequestid",
    path: "/my-shop/my-requests/:sellerrequestid()",
    meta: indexXADfeADn5jMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/index.vue")
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-offer",
    path: "/my-shop/my-requests/:sellerrequestid()/send-offer",
    meta: send_45offerbgbvkzFDtPMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-offer.vue")
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-review",
    path: "/my-shop/my-requests/:sellerrequestid()/send-review",
    meta: send_45reviewYrDrM5DvfSMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-review.vue")
  },
  {
    name: "my-shop-my-requests",
    path: "/my-shop/my-requests",
    meta: index2wsHnC3NQSMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/index.vue")
  },
  {
    name: "my-shop-payment-method-payment",
    path: "/my-shop/payment-method/:payment()",
    meta: _91payment_93mcHzIJEeQRMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue")
  },
  {
    name: "my-shop-response",
    path: "/my-shop/response",
    meta: responseEibv5v2ENSMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/response.vue")
  },
  {
    name: "my-shop-settings",
    path: "/my-shop/settings",
    meta: settings0I2jNIFcEJMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/settings.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsMqQz3wpp0sMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/notifications.vue")
  },
  {
    name: "partners-become-seller",
    path: "/partners/become-seller",
    meta: become_45sellersI6ump9t0aMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/become-seller.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/index.vue")
  },
  {
    name: "password-reset-hash",
    path: "/password-reset/:hash()",
    meta: _91hash_931lgRYiTcOsMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/password-reset/[hash].vue")
  },
  {
    name: "profile-change-name",
    path: "/profile/change-name",
    meta: change_45name27FoAiLroFMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-name.vue")
  },
  {
    name: "profile-change-phone",
    path: "/profile/change-phone",
    meta: change_45phonegBs3lDmgEHMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-phone.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexfDzw5m4X88Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerGBaFG5I6miMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/register.vue")
  },
  {
    name: "request",
    path: "/request",
    meta: requestky05NOJUC7Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/request.vue")
  },
  {
    name: "settings-change-email",
    path: "/settings/change-email",
    meta: change_45email6XD6znkaudMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/change-email.vue")
  },
  {
    name: "settings-change-password",
    path: "/settings/change-password",
    meta: change_45passwordZNFcLsjlCzMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/change-password.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexjI0GsFeuMkMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/settings/index.vue")
  },
  {
    name: "shop-id",
    path: "/shop/:id()",
    meta: indexP9q3i83eCzMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/index.vue")
  },
  {
    name: "shop-id-rating",
    path: "/shop/:id()/rating",
    meta: ratingWnMMU9YeIKMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/rating.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/support/index.vue")
  },
  {
    name: "transactions-id",
    path: "/transactions/:id()",
    meta: _91id_93HSXz8I1ga6Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/transactions/[id].vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: index4Pajql4nP9Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/transactions/index.vue")
  },
  {
    name: "wallet-failure",
    path: "/wallet/failure",
    meta: failuremhSOrUtCRMMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/failure.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: indexJRXpYOz2RbMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/index.vue")
  },
  {
    name: "wallet-info",
    path: "/wallet/info",
    meta: infoo9EmPlkt0zMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/info.vue")
  },
  {
    name: "wallet-transactions",
    path: "/wallet/transactions",
    meta: transactionsERLdx5tByPMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/transactions.vue")
  }
]